var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "li",
    {
      staticClass: "nav-item dropdown",
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          on: {
            click: function ($event) {
              return _vm.toggleDropdown("vip-account")
            },
          },
        },
        [
          _c("avatar", {
            attrs: {
              avatarUrl: _vm.profile_base.photo,
              avatarRole: "student",
            },
          }),
          _c(
            "span",
            {
              staticClass: "ml-2 d-lg-down-none",
            },
            [_vm._v(_vm._s(_vm.profile_base.chinese_name))]
          ),
        ],
        1
      ),
      _c(
        "transition",
        {
          attrs: {
            name: "fade",
          },
        },
        [
          _vm.state === "vip-account"
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu dropdown-menu-right d-block",
                },
                [
                  _c("detail", {
                    attrs: {
                      role: _vm.student,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }