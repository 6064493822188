var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "scroll-top position-fixed m-2",
    },
    [
      _c(
        "a",
        {
          attrs: {
            href: "#top",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "rounded-circle bg-secondary text-light d-flex justify-content-center align-items-center h2",
              staticStyle: {
                height: "50px",
                width: "50px",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: "arrow-up",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }