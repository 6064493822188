// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/teachers_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".subtitle[data-v-54460d76] {\n  font-size: 20px;\n  letter-spacing: 4px;\n}\n.consultants .slide-card[data-v-54460d76] {\n  background-color: white;\n  box-shadow: 0px 3px 6px #00000029;\n  border-radius: 7px;\n}\n.consultants .slide-card .slide-card-title[data-v-54460d76] {\n    font-size: 23px;\n}\n.consultants .slide-card .slide-card-btn[data-v-54460d76] {\n    font-size: 22px;\n    color: #707070;\n}\n.consultants .slide-card .nationality-img[data-v-54460d76] {\n    width: 20px;\n}\n.consultants .bg-img[data-v-54460d76] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n}\n.consultants .consultants-carousel[data-v-54460d76] {\n  width: 90%;\n}\n@media screen and (max-width: 1200px) {\n.consultants .slide-card .slide-card-title[data-v-54460d76] {\n    font-size: 20px;\n}\n.consultants .slide-card .slide-card-btn[data-v-54460d76] {\n    font-size: 20px;\n    color: #707070;\n}\n}\n@media screen and (max-width: 414px) {\n.subtitle[data-v-54460d76] {\n    font-size: 15px;\n    letter-spacing: 3px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
