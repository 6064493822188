var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "training mt-5 home-container",
      attrs: {
        id: "training",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "row mx-0",
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-8 col-lg-12 mx-auto",
            },
            [
              _c(
                "div",
                {
                  staticClass: "row",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "square p-3 col-12 col-lg-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "square-content ratio ratio-1x1",
                        },
                        [
                          _c("img", {
                            staticClass: "content-img w-100",
                            attrs: {
                              src: _vm.file.training1,
                            },
                          }),
                          _vm._m(1),
                          _vm._m(2),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "square p-3 col-12 col-lg-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "square-content ratio ratio-1x1",
                        },
                        [
                          _c("img", {
                            staticClass: "content-img w-100",
                            attrs: {
                              src: _vm.file.training2,
                            },
                          }),
                          _vm._m(3),
                          _vm._m(4),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "square p-3 col-12 col-lg-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "square-content ratio ratio-1x1",
                        },
                        [
                          _c("img", {
                            staticClass: "content-img w-100",
                            attrs: {
                              src: _vm.file.training3,
                            },
                          }),
                          _vm._m(5),
                          _vm._m(6),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "col-12 d-flex justify-content-center mb-4",
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "area-title title text-lioshutan rounded-pill px-4 py-2",
          },
          [_vm._v("精選師資陣容")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "content-title d-flex align-items-center justify-content-center",
      },
      [
        _c(
          "h3",
          {
            staticClass: "area-content-title m-0 text-lioshutan",
          },
          [_vm._v("英文教學專業認證")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "content-text mb-0 d-flex align-items-center",
      },
      [
        _c(
          "div",
          {
            staticClass: "area-text",
          },
          [_vm._v("全師資擁有 TESOL / TEFL 至少其一的國際教學證照。")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "content-title d-flex align-items-center justify-content-center",
      },
      [
        _c(
          "h3",
          {
            staticClass: "area-content-title m-0 text-lioshutan",
          },
          [_vm._v(" 1 年以上的兒美教學經驗")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "content-text mb-0 d-flex align-items-center",
      },
      [
        _c(
          "div",
          {
            staticClass: "area-text",
          },
          [_vm._v("全師資都擁有至少一年以上的兒美教學經驗。")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "content-title d-flex align-items-center justify-content-center",
      },
      [
        _c(
          "h3",
          {
            staticClass: "area-content-title m-0 text-lioshutan",
          },
          [_vm._v("定期追蹤回訓")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "content-text mb-0 d-flex align-items-center",
      },
      [
        _c(
          "div",
          {
            staticClass: "area-text",
          },
          [
            _vm._v(
              "依照課程評分系統，我們將對顧問進行回訓，穩定每位老師的教學品質。"
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }