var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.waitData
    ? _c(
        "b-nav",
        [
          _vm.role === _vm.student
            ? _c("vip-header-nav")
            : _vm.role === _vm.parent
            ? _c("parent-header-nav")
            : _vm.role === _vm.teacher
            ? _c("consultant-header-nav")
            : _vm.role === _vm.center
            ? _c("center-header-nav")
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }