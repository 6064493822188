var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "reg-login",
      },
      [
        _c(
          "a",
          {
            staticClass: "lnk-login-home",
            attrs: {
              href: "http://www.wuwow.tw",
            },
          },
          [
            _c("font-awesome-icon", {
              attrs: {
                icon: "angle-left",
              },
            }),
            _vm._v(" " + _vm._s(_vm.$t("back_to_official_web"))),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "are-login-input",
          },
          [
            _c(
              "h1",
              {
                staticClass: "h1-login-title",
              },
              [_vm._v(_vm._s(_vm.$t("login_title")))]
            ),
            _c(
              "p",
              {
                staticClass: "txt-login-desc",
              },
              [_vm._v(_vm._s(_vm.$t("wuwow_login_slogan")))]
            ),
            _c("form", [
              _c(
                "div",
                {
                  staticClass: "form-group",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.loginForm.account,
                        expression: "loginForm.account",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "email",
                      type: "text",
                      placeholder: "Mobile Phone / Account Name",
                    },
                    domProps: {
                      value: _vm.loginForm.account,
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.loginForm, "account", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group blk-login-password",
                },
                [
                  _vm.textOrPassword === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.password,
                            expression: "loginForm.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          name: "password",
                          placeholder: "Password",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.loginForm.password)
                            ? _vm._i(_vm.loginForm.password, null) > -1
                            : _vm.loginForm.password,
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.login.apply(null, arguments)
                          },
                          change: function ($event) {
                            var $$a = _vm.loginForm.password,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.loginForm,
                                    "password",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.loginForm,
                                    "password",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.loginForm, "password", $$c)
                            }
                          },
                        },
                      })
                    : _vm.textOrPassword === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.password,
                            expression: "loginForm.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          name: "password",
                          placeholder: "Password",
                          type: "radio",
                        },
                        domProps: {
                          checked: _vm._q(_vm.loginForm.password, null),
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.login.apply(null, arguments)
                          },
                          change: function ($event) {
                            return _vm.$set(_vm.loginForm, "password", null)
                          },
                        },
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.password,
                            expression: "loginForm.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          name: "password",
                          placeholder: "Password",
                          type: _vm.textOrPassword,
                        },
                        domProps: {
                          value: _vm.loginForm.password,
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.login.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.loginForm,
                              "password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: _vm.eyeIcon,
                      "aria-hidden": "true",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.errorMessage
                ? _c(
                    "div",
                    {
                      staticClass: "my-2",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("user_info_error_message"))),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "row blk-login-links",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "lnk-login-reset-pwd",
                      attrs: {
                        href: "https://www.wuwow.tw/trial/?m=wuwow_homepage",
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "question-circle",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("get_new_password"))),
                    ],
                    1
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "lnk-login-trial",
                      attrs: {
                        href: "https://www.wuwow.tw/trial/?m=wuwow_homepage",
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "gift",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("free_trial"))),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block btn-login",
                      attrs: {
                        type: "button",
                      },
                      on: {
                        click: _vm.login,
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("login")))]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }