var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "jr-nav-bar",
      class: _vm.addFixedNavClass ? "fixed-nav-bar" : "",
    },
    [
      _c(
        "div",
        {
          staticClass: "d-block d-1600-none home-container position-relative",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "bg-transparent border-0 text-light h2 m-0 mobile-size",
                  on: {
                    click: function ($event) {
                      _vm.isLeftNavShow = true
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: "bars",
                    },
                  }),
                ],
                1
              ),
              _vm._m(0),
              _c(
                "button",
                {
                  staticClass:
                    "btn-link border-0 h3 text-light m-0 rounded-pill mobile-size p-0",
                  on: {
                    click: function ($event) {
                      return _vm.setIsOpen({
                        target: _vm.modal.CHOICE_CHARACTER_UUID,
                        status: !_vm.choiceCharacterModalIsOpen,
                      })
                    },
                  },
                },
                [_vm._v("登入")]
              ),
            ]
          ),
          _vm.isLeftNavShow
            ? _c(
                "div",
                {
                  staticClass: "position-fixed w-100 h-100 fixed-top",
                  on: {
                    click: function ($event) {
                      _vm.isLeftNavShow = false
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left-side-bar h-100 w-75 bg-lioshutan",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-items-center justify-content-center pt-4",
                        },
                        [
                          _vm.deadlinesAfter
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "children-nav my-3",
                                  attrs: {
                                    to: {
                                      path: "/event/childrensday_2024",
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/childrensday2024/nav.png"),
                                      alt: "classRoom",
                                    },
                                  }),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/childrensday2024/nav-hover.png"),
                                      alt: "classRoom",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                href: "#material",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isLeftNavShow = false
                                },
                              },
                            },
                            [_vm._v("課程特色")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                href: "#consultants",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isLeftNavShow = false
                                },
                              },
                            },
                            [_vm._v("師資陣容")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                href: "#arcs",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isLeftNavShow = false
                                },
                              },
                            },
                            [_vm._v("ARCS教學模式")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                href: "#recommend",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isLeftNavShow = false
                                },
                              },
                            },
                            [_vm._v("好評推薦")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                to: {
                                  name: "event-trial",
                                },
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isLeftNavShow = false
                                },
                              },
                            },
                            [_vm._v("免費試讀")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                href: "https://core.newebpay.com/EPG/WUWOWJR/H4ptZd",
                              },
                            },
                            [_vm._v("線上購買")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "h2 text-decoration-none text-light my-3",
                              attrs: {
                                href:
                                  _vm.officialURL +
                                  "recruitment/pm_recruitment",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isLeftNavShow = false
                                },
                              },
                            },
                            [_vm._v("成為老師")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "login-btn border-0 h2 bg-light text-lioshutan m-0 rounded-pill my-3",
                              on: {
                                click: function ($event) {
                                  return _vm.setIsOpen({
                                    target: _vm.modal.CHOICE_CHARACTER_UUID,
                                    status: !_vm.choiceCharacterModalIsOpen,
                                  })
                                },
                              },
                            },
                            [_vm._v("會員登入")]
                          ),
                        ],
                        1
                      ),
                      _vm._m(1),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-items-center justify-content-center",
                        },
                        [
                          _c(
                            "h4",
                            {
                              staticClass: "text-light",
                            },
                            [_vm._v("Follow us on")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center w-100",
                            },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: _vm.file.fbSVG,
                                  width: "50",
                                  height: "50",
                                },
                              }),
                              _c("img", {
                                staticClass: "mx-2",
                                attrs: {
                                  src: _vm.file.lineSVG,
                                  width: "50",
                                  height: "50",
                                },
                              }),
                              _c("img", {
                                staticClass: "ml-2",
                                attrs: {
                                  src: _vm.file.messengerSVG,
                                  width: "50",
                                  height: "50",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._m(2),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "d-none d-md-none d-xl-none d-1600-block container-fluid",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center home-container",
            },
            [
              _vm._m(3),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _vm.deadlinesAfter
                    ? _c(
                        "router-link",
                        {
                          staticClass: "children-nav my-3",
                          attrs: {
                            to: {
                              path: "/event/childrensday_2024",
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/childrensday2024/nav.png"),
                              alt: "classRoom",
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/childrensday2024/nav-hover.png"),
                              alt: "classRoom",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._m(4),
                  _vm._m(5),
                  _vm._m(6),
                  _vm._m(7),
                  _c(
                    "router-link",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        to: {
                          name: "event-trial",
                        },
                      },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
                        },
                        [_vm._v("免費試讀")]
                      ),
                    ]
                  ),
                  _vm._m(8),
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        href: _vm.officialURL + "recruitment/pm_recruitment",
                      },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
                        },
                        [_vm._v("成為老師")]
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "login-btn border-0 text-lioshutan m-0 rounded-pill px-4 py-1",
                      on: {
                        click: function ($event) {
                          return _vm.setIsOpen({
                            target: _vm.modal.CHOICE_CHARACTER_UUID,
                            status: !_vm.choiceCharacterModalIsOpen,
                          })
                        },
                      },
                    },
                    [_vm._v("會員登入")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        attrs: {
          href: "/",
        },
      },
      [
        _c("img", {
          staticClass: "jr-logo",
          attrs: {
            src: "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-column align-items-center justify-content-center",
      },
      [
        _c(
          "div",
          {
            staticClass: "w-75",
          },
          [
            _c("hr", {
              staticClass: "bg-light",
            }),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-column align-items-center justify-content-center mt-5",
      },
      [
        _c(
          "p",
          {
            staticClass: "text-light mb-1",
          },
          [_vm._v("異業提案合作")]
        ),
        _c(
          "p",
          {
            staticClass: "text-light mb-1",
          },
          [_vm._v("service@wuwowjr.tw")]
        ),
        _c(
          "p",
          {
            staticClass: "text-light mb-1",
          },
          [_vm._v("Copyright 2022@LIOSHUTAN")]
        ),
        _c(
          "p",
          {
            staticClass: "text-light mb-1",
          },
          [_vm._v("All Rights Reserved")]
        ),
        _c(
          "p",
          {
            staticClass: "text-light mb-1",
          },
          [_vm._v("隱私權政策")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        attrs: {
          href: "/",
        },
      },
      [
        _c("img", {
          staticClass: "jr-logo",
          attrs: {
            src: "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "text-decoration-none",
        attrs: {
          href: "#material",
        },
      },
      [
        _c(
          "h5",
          {
            staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
          },
          [_vm._v("課程特色")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "text-decoration-none",
        attrs: {
          href: "#consultants",
        },
      },
      [
        _c(
          "h5",
          {
            staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
          },
          [_vm._v("師資陣容")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "text-decoration-none",
        attrs: {
          href: "#arcs",
        },
      },
      [
        _c(
          "h5",
          {
            staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
          },
          [_vm._v("ARCS教學模式")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "text-decoration-none",
        attrs: {
          href: "#recommend",
        },
      },
      [
        _c(
          "h5",
          {
            staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
          },
          [_vm._v("好評推薦")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "text-decoration-none",
        attrs: {
          href: "https://core.newebpay.com/EPG/WUWOWJR/H4ptZd",
        },
      },
      [
        _c(
          "h5",
          {
            staticClass: "under-line text-light m-0 mx-xl-3 mx-3",
          },
          [_vm._v("線上購買")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }