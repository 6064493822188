// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/thank_you_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.thank-you-box[data-v-64ec001e]{\n  height: 100vh;\n  text-align: center;\n  background-color: #F5F5F5;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.thank-you-content[data-v-64ec001e]{\n  font-size: 18px;\n  color: var( --text-light-color );\n}\n.go-back-home-btn[data-v-64ec001e]{\n  font-size: 16px;\n  padding: 15px 80px;\n}\n", ""]);
// Exports
module.exports = exports;
