var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _vm._v(_vm._s(_vm.$t("class.remain_class")) + "："),
    _c(
      "p",
      {
        staticClass: "float-right remain-class",
      },
      [
        _c(
          "span",
          {
            staticClass: "txt-personal-sessions",
          },
          [
            _vm._v(
              _vm._s(_vm.vip_account_info.elapsed_time.balance_sessions) + " "
            ),
          ]
        ),
        _c("span", [_vm._v(_vm._s(_vm.$t("class.class")))]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "mt-3",
      },
      [
        _vm._v(_vm._s(_vm.$t("contract.contract"))),
        _c("br"),
        _c(
          "span",
          {
            staticClass: "contract-time-text",
          },
          [_vm._v(_vm._s(_vm.vip_account_info.elapsed_time.contract_start))]
        ),
        _c(
          "span",
          {
            staticClass: "contract-time-text contract-time-end float-right",
          },
          [_vm._v(_vm._s(_vm.vip_account_info.elapsed_time.contract_end))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }