var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.supplementaryAgreement,
        size: "lg",
        "header-text-variant": "black",
        "header-bg-variant": "primary",
        "hide-footer": true,
        "hide-header-close": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                },
                [_vm._v("增補協議條款")]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                  },
                  on: {
                    click: _vm.handleClose,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        "aria-hidden": "true",
                      },
                    },
                    [_vm._v("x")]
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass: "title",
        },
        [_c("h3", [_vm._v(_vm._s(_vm.vipContractAdditionTerm.title))])]
      ),
      _c(
        "div",
        {
          staticClass: "content ckeditor-table-style",
        },
        [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.vipContractAdditionTerm.waitingDirections),
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "reply-choices",
        },
        [
          _c(
            "div",
            {
              staticClass: "mx-1",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                staticClass: "mr-1",
                attrs: {
                  type: "radio",
                  value: "approved",
                  id: "agreeBtn",
                },
                domProps: {
                  checked: _vm._q(_vm.choice, "approved"),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = "approved"
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: {
                    for: "agreeBtn",
                  },
                },
                [_vm._v(" 同意")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "mx-1",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                staticClass: "mr-1",
                attrs: {
                  type: "radio",
                  value: "rejected",
                  id: "rejectBtn",
                },
                domProps: {
                  checked: _vm._q(_vm.choice, "rejected"),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = "rejected"
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: {
                    for: "rejectBtn",
                  },
                },
                [_vm._v("拒絕")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mx-1 mt-3 ckeditor-table-style",
        },
        [
          _c("p", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.choice === "approved",
                expression: "choice === 'approved'",
              },
            ],
            domProps: {
              innerHTML: _vm._s(_vm.vipContractAdditionTerm.approvedDirections),
            },
          }),
          _c("p", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.choice === "rejected",
                expression: "choice === 'rejected'",
              },
            ],
            domProps: {
              innerHTML: _vm._s(_vm.vipContractAdditionTerm.rejectedDirections),
            },
          }),
        ]
      ),
      _vm.vipContractAdditionTerm.note !== "" && _vm.choice === "approved"
        ? _c(
            "div",
            {
              staticClass: "mx-1 mt-3 ckeditor-table-style",
            },
            [
              _c(
                "h2",
                {
                  staticClass: "agree-gift-content",
                },
                [_vm._v("同意後贈送好禮")]
              ),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.vipContractAdditionTerm.note),
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "submit-btn",
        },
        [
          _c(
            "button",
            {
              attrs: {
                disabled: !_vm.choice,
              },
              on: {
                click: _vm.handleSendResults,
              },
            },
            [_vm._v("送出")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }