var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "ul",
    {
      staticClass: "nav",
    },
    [
      _c(
        "li",
        {
          staticClass: "nav-item",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "nav-link",
              attrs: {
                to: {
                  path: "/dojo/classRoom",
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("attendClass")))]
          ),
        ],
        1
      ),
      _vm.profile_base.is_enabled_vip_product && _vm.getCanOrderClass
        ? _c(
            "li",
            {
              staticClass: "nav-item",
            },
            [
              _c(
                "div",
                {
                  staticClass: "nav-link",
                },
                [_vm._v(_vm._s(_vm.$t("bookClass")))]
              ),
              _c(
                "ul",
                {
                  staticClass: "subnav",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "subnav-link subnav-sort",
                    },
                    [_vm._v(_vm._s(_vm.$t("book")))]
                  ),
                  _vm._l(_vm.bookLists, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.name,
                        staticClass: "subnav-item",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "dropdown-item subnav-link",
                            attrs: {
                              to: {
                                path: item.path,
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t(item.name)))]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "subnav-link subnav-sort",
                    },
                    [_vm._v(_vm._s(_vm.$t("setRecord")))]
                  ),
                  _vm._l(_vm.setRecordLists, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.name,
                        staticClass: "subnav-item",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "dropdown-item subnav-link",
                            attrs: {
                              to: {
                                path: item.path,
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t(item.name)))]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._m(0),
                  _vm._m(1),
                  _vm._m(2),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _c(
        "li",
        {
          staticClass: "nav-item",
        },
        [
          _c(
            "div",
            {
              staticClass: "nav-link",
            },
            [_vm._v(_vm._s(_vm.$t("classRecord")))]
          ),
          _c(
            "ul",
            {
              staticClass: "subnav",
            },
            _vm._l(_vm.classRecordLists, function (item) {
              return _c(
                "li",
                {
                  key: item.name,
                  staticClass: "subnav-item",
                },
                [
                  item.authorityTag && item.name !== "learnRecords"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item subnav-link",
                          attrs: {
                            to: {
                              path: item.path,
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                      )
                    : _vm._e(),
                  item.authorityTag && item.name === "learnRecords"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item subnav-link",
                          attrs: {
                            href: `${_vm.newJrUrl}${item.path}`,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "nav-item",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "nav-link",
              attrs: {
                to: {
                  path: "/dojo/vip_mgm",
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("vipMgm")))]
          ),
        ],
        1
      ),
      _c(
        "li",
        {
          staticClass: "nav-item",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "nav-link",
              attrs: {
                to: {
                  path: "/dojo/guide",
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("guide")))]
          ),
        ],
        1
      ),
      _vm._m(3),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "li",
      {
        staticClass: "subnav-item",
      },
      [
        _c(
          "a",
          {
            staticClass: "dropdown-item subnav-link",
            attrs: {
              href: "https://cws.cavesbooks.com.tw/index.aspx?id=h%2fyJTTRu%2bLjpHeEYZ6gbKA%3d%3d",
              target: "_blank",
            },
          },
          [_vm._v("P21音檔")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "li",
      {
        staticClass: "subnav-item",
      },
      [
        _c(
          "a",
          {
            staticClass: "dropdown-item subnav-link",
            attrs: {
              href: "https://cws.cavesbooks.com.tw/index.aspx?id=Ootb88ICU73n%2b9F3YSL3ig%3d%3d",
              target: "_blank",
            },
          },
          [_vm._v("R21音檔")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "li",
      {
        staticClass: "subnav-item",
      },
      [
        _c(
          "a",
          {
            staticClass: "dropdown-item subnav-link",
            attrs: {
              href: "https://www.cavesbooks.com.tw/EC/book_fair.aspx#3191",
              target: "_blank",
            },
          },
          [_vm._v("魔法故事屋")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "li",
      {
        staticStyle: {
          height: "40px",
        },
      },
      [_c("p")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }