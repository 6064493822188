var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "pt-4",
      attrs: {
        id: "recommend",
      },
    },
    [
      _c(
        "p",
        {
          staticClass: "text-lioshutan area-english-title text-center",
        },
        [_vm._v(":: Recommendation ::")]
      ),
      _c(
        "h2",
        {
          staticClass: "text-lioshutan area-title text-center",
        },
        [_vm._v("快樂學習 好評推薦")]
      ),
      _c(
        "h4",
        {
          staticClass: "area-description text-center",
        },
        [_vm._v("所有 WUWOW Jr 的孩子們，每天最期待的事—展開英文學習！")]
      ),
      _c(
        "div",
        {
          staticClass: "home-container",
        },
        [
          _c(
            "carousel",
            {
              staticClass: "p-2",
              attrs: {
                perPage: 1,
                perPageCustom: [
                  [768, 3],
                  [992, 4],
                ],
                autoplay: true,
                autoplayTimeout: 5000,
                speed: 1000,
                loop: true,
                autoplayHoverPause: true,
                navigationEnabled: true,
              },
            },
            _vm._l(_vm.cardList, function (card, index) {
              return _c(
                "slide",
                {
                  staticClass: "justify-content-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "slider-card",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "thumb text-center",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "tag",
                            },
                            [_vm._v(_vm._s(card.tag))]
                          ),
                          _c("img", {
                            attrs: {
                              src: card.thumb,
                              alt: `${card.topic}的預覽圖片`,
                            },
                          }),
                          card.video
                            ? _c(
                                "button",
                                {
                                  staticClass: "play",
                                  on: {
                                    click: function ($event) {
                                      return _vm.youtubeModal(card.embed)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "40",
                                        height: "40",
                                        viewbox: "0 0 40 40",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("circle", {
                                        attrs: {
                                          cx: "20",
                                          cy: "20",
                                          r: "20",
                                          fill: "#82C1EA",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M28.6638 19.4617C29.3305 19.8466 29.3305 20.8089 28.6638 21.1938L16.9097 27.98C16.2431 28.3649 15.4097 27.8838 15.4097 27.114L15.4097 13.5415C15.4097 12.7717 16.2431 12.2906 16.9097 12.6755L28.6638 19.4617Z",
                                          fill: "white",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "info text-center",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "topic",
                                },
                                [_vm._v(_vm._s(card.topic))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "detail",
                                },
                                [
                                  _c("P", [_vm._v(_vm._s(card.name))]),
                                  card.age
                                    ? _c("p", [
                                        _vm._v(" " + _vm._s(card.age) + "歲"),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-title d-flex justify-content-center align-items-center",
                            },
                            [_c("h5", [_vm._v(_vm._s(card.title))])]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "content",
                            },
                            [_vm._v(_vm._s(card.content))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "control d-flex",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "link d-flex ml-auto align-items-center",
                                  attrs: {
                                    href: card.link,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("觀看更多")]),
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "20",
                                        height: "20",
                                        viewBox: "0 0 20 20",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("circle", {
                                        attrs: {
                                          cx: "10",
                                          cy: "10",
                                          r: "9.3",
                                          fill: "white",
                                          stroke: "#C7C7C7",
                                          "stroke-width": "1.4",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M4.87818 9.58436C4.98848 9.47405 5.13809 9.41208 5.29408 9.41208H12.1086L9.58301 6.88766C9.47257 6.77722 9.41052 6.62743 9.41052 6.47124C9.41052 6.31505 9.47257 6.16526 9.58301 6.05481C9.69346 5.94437 9.84325 5.88232 9.99944 5.88232C10.1556 5.88232 10.3054 5.94437 10.4159 6.05481L13.9449 9.58383C13.9997 9.63847 14.0431 9.70337 14.0728 9.77483C14.1024 9.84628 14.1177 9.92289 14.1177 10.0003C14.1177 10.0776 14.1024 10.1542 14.0728 10.2257C14.0431 10.2971 13.9997 10.362 13.9449 10.4167L10.4159 13.9457C10.3612 14.0004 10.2963 14.0438 10.2248 14.0734C10.1534 14.103 10.0768 14.1182 9.99944 14.1182C9.9221 14.1182 9.84552 14.103 9.77407 14.0734C9.70262 14.0438 9.6377 14.0004 9.58301 13.9457C9.52833 13.891 9.48495 13.8261 9.45535 13.7546C9.42576 13.6832 9.41052 13.6066 9.41052 13.5293C9.41052 13.4519 9.42576 13.3754 9.45535 13.3039C9.48495 13.2325 9.52833 13.1675 9.58301 13.1128L12.1086 10.5884H5.29408C5.13809 10.5884 4.98848 10.5265 4.87818 10.4162C4.76788 10.3059 4.70591 10.1562 4.70591 10.0003C4.70591 9.84426 4.76788 9.69466 4.87818 9.58436Z",
                                          fill: "#C7C7C7",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }