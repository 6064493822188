var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "bg-home",
      attrs: {
        id: "top",
      },
    },
    [
      _c("navigation"),
      _c("advertise"),
      _c("service-introduction"),
      _c("div", {
        attrs: {
          id: "linkToVideoLocation",
        },
      }),
      _c(
        "main",
        {
          staticClass: "main",
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "main-content",
            },
            [
              _c(
                "div",
                {
                  staticClass: "tabs",
                },
                [
                  _c(
                    "ul",
                    _vm._l(_vm.videos, function (item) {
                      return _c(
                        "li",
                        {
                          key: item.name,
                          class: {
                            active: item.name === _vm.currentVideo.name,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectVideo(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.img,
                            },
                          }),
                          _c("div", [
                            _c("h3", [_vm._v(_vm._s(item.name))]),
                            _c("time", [_vm._v(_vm._s(item.time))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "videoWrap",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "videoContent",
                    },
                    [
                      _vm.videoShow
                        ? _c(
                            "video",
                            {
                              attrs: {
                                muted: "",
                                playsinline: "",
                                controls: "",
                              },
                              domProps: {
                                muted: true,
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.currentVideo.file,
                                  type: "video/mp4",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("text-book"),
      _c("arcs-modal", {
        staticClass: "bg-img",
      }),
      _c("trp-modal"),
      _c("consultants"),
      _c("timeline"),
      _c("recommend"),
      _c("question-and-answer"),
      _c("reserve-class"),
      _c("lioshutan-footer"),
      _c("youtube-modal", {
        attrs: {
          src: _vm.youtubeUrl,
        },
      }),
      _c("scroll-to-top-button"),
      _c("login-modal"),
      _c("choice-character-modal"),
      _c("parent-login-modal"),
      _c("forgot-password-modal"),
      _c("register-parent-account-modal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "main-box",
      },
      [
        _c(
          "div",
          {
            staticClass: "class-title",
          },
          [
            _vm._v("::Class Recording ::"),
            _c("br"),
            _c(
              "span",
              {
                staticClass: "first-title",
              },
              [_vm._v("學員課程實錄")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }