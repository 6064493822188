var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "sidebar",
    },
    [
      _c(
        "nav",
        {
          staticClass: "sidebar-nav",
          staticStyle: {
            "max-height": "80vh",
            "min-height": "0",
          },
        },
        [
          _vm.role === _vm.student ? _c("vip-left-nav") : _vm._e(),
          _vm.role === _vm.parent
            ? _c("parent-left-nav")
            : _vm.role === _vm.teacher
            ? _c("consultant-left-nav")
            : _vm.role === _vm.center
            ? _c("center-left-nav")
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }