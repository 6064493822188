var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        centered: "",
        "hide-footer": "",
      },
      on: {
        change: _vm.close,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "choice-title",
        },
        [
          _c(
            "p",
            {
              staticClass: "jr-light-color text-center",
            },
            [_vm._v("請選擇 登入/註冊 角色")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center mt-4",
        },
        [
          _c(
            "a",
            {
              staticClass: "p-3 m-2 login-role-img",
              on: {
                click: function ($event) {
                  return _vm.changeCharacter(_vm.CHARACTER.CHILDREN)
                },
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-center",
                },
                [_vm._v("學員")]
              ),
              _c("img", {
                staticClass: "w-100",
                attrs: {
                  src: _vm.file.studentLoginImg,
                },
              }),
            ]
          ),
          _c(
            "a",
            {
              staticClass: "p-3 m-2 login-role-img",
              on: {
                click: function ($event) {
                  return _vm.changeCharacter(_vm.CHARACTER.PARENT)
                },
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-center",
                },
                [_vm._v("家長")]
              ),
              _c("img", {
                staticClass: "w-100",
                attrs: {
                  src: _vm.file.parentLoginImg,
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }