var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        centered: "",
        "hide-header": "",
        "hide-footer": "",
      },
      on: {
        change: _vm.initData,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.modalType === 1,
              expression: "modalType === 1",
            },
          ],
        },
        [
          _c(
            "h2",
            {
              staticClass: "my-5 main-color",
            },
            [_vm._v("忘記密碼")]
          ),
          _c(
            "div",
            {
              staticClass: "form-group mx-auto text-left",
            },
            [
              _c(
                "p",
                {
                  staticClass: "mb-2",
                },
                [_vm._v("信箱帳號")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.email,
                    expression: "email",
                    modifiers: {
                      trim: true,
                    },
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  placeholder: "輸入註冊時信箱帳號",
                },
                domProps: {
                  value: _vm.email,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex mt-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "info-icon mr-2",
                    },
                    [_vm._v("i")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "danger-color",
                    },
                    [
                      _vm._v("密碼重設連結僅在15分鐘內有效，"),
                      _c("br"),
                      _vm._v("若尚未收到信件請前往查看垃圾信件匣"),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "my-5",
            },
            [
              _c(
                "button",
                {
                  staticClass: "w-25 btn btn-outline-primary mx-2",
                  on: {
                    click: _vm.initData,
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "button",
                {
                  staticClass: "w-25 btn btn-primary mx-2",
                  on: {
                    click: _vm.sendChangePasswordRequest,
                  },
                },
                [_vm._v("完成")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.modalType === 2,
              expression: "modalType === 2",
            },
          ],
        },
        [
          _c(
            "h2",
            {
              staticClass: "my-5 main-color",
            },
            [_vm._v("重設密碼")]
          ),
          _c(
            "div",
            {
              staticClass: "form-group mx-auto",
            },
            [
              _c("span", [
                _vm._v(
                  "密碼重設信件已送至下方信箱，請點擊信件中的連結重設密碼"
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "d-flex mt-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "info-icon mr-2",
                    },
                    [_vm._v("i")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "danger-color",
                    },
                    [_vm._v("密碼重設連結僅在15分鐘內有效，")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 p-2 show-email mt-4",
                },
                [_vm._v(_vm._s(_vm.email))]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }