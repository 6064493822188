var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "b-skeleton-wrapper",
        {
          attrs: {
            loading: _vm.loading,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "loading",
                fn: function () {
                  return [_vm._t("skeleton-template")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [_vm._t("origin-template")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }