var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "my-5 consultants",
      attrs: {
        id: "consultants",
      },
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "pb-5 bg-img",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-block d-md-none",
            },
            [
              _c(
                "div",
                {
                  staticClass: "container",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-items-center py-3",
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "text-lioshutan",
                        },
                        [_vm._v("英文顧問團隊")]
                      ),
                      _c(
                        "h4",
                        {
                          staticClass: "text-lioshutan",
                        },
                        [_vm._v("Meet our teachers!")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-75",
                        },
                        [
                          _c(
                            "carousel",
                            {
                              attrs: {
                                perPage: 1,
                              },
                            },
                            _vm._l(_vm.exampleData, function (item) {
                              return _c("slide", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "p-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "slide-card d-flex flex-column align-items-center py-4 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-75",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ratio ratio-1x1",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "w-100 h-100 rounded",
                                                  attrs: {
                                                    src: item.image,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "h4",
                                          {
                                            staticClass: "text-lioshutan my-3",
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                          },
                                          [
                                            _vm._v(_vm._s(item.nationality)),
                                            _c("img", {
                                              staticClass:
                                                "ml-1 nationality-img",
                                              attrs: {
                                                src: item.nationalityImg,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-100 px-3",
                                          },
                                          [_c("hr")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link text-decoration-none text-dark my-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.test(
                                                  item.introduction
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v("導師介紹"),
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: "video",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-none d-md-block d-lg-none",
            },
            [
              _c(
                "div",
                {
                  staticClass: "home-container",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-items-center py-3",
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "area-title text-lioshutan",
                        },
                        [_vm._v("英文顧問團隊")]
                      ),
                      _c(
                        "h4",
                        {
                          staticClass: "area-text text-lioshutan",
                        },
                        [_vm._v("Meet our teachers!")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "consultants-carousel mt-4",
                        },
                        [
                          _c(
                            "carousel",
                            {
                              attrs: {
                                perPage: 3,
                              },
                            },
                            _vm._l(_vm.exampleData, function (item) {
                              return _c("slide", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "p-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 slide-card d-flex flex-column align-items-center py-4 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-75",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ratio ratio-1x1",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "w-100 h-100",
                                                  attrs: {
                                                    src: item.image,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "slide-card-title text-lioshutan my-3",
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "area-text m-0",
                                          },
                                          [
                                            _vm._v(_vm._s(item.nationality)),
                                            _c("img", {
                                              staticClass:
                                                "ml-1 nationality-img",
                                              attrs: {
                                                src: item.nationalityImg,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-100 px-3",
                                          },
                                          [_c("hr")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "slide-card-btn btn text-decoration-none my-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.test(
                                                  item.introduction
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v("導師介紹"),
                                            _c("font-awesome-icon", {
                                              staticClass: "ml-1",
                                              attrs: {
                                                icon: "video",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-none d-md-none d-lg-block",
            },
            [
              _c(
                "div",
                {
                  staticClass: "home-container",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-items-center py-3",
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "area-title text-lioshutan",
                        },
                        [_vm._v("英文顧問團隊")]
                      ),
                      _c(
                        "h4",
                        {
                          staticClass: "area-text text-lioshutan",
                        },
                        [_vm._v("Meet our teachers!")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "consultants-carousel mt-4",
                        },
                        [
                          _c(
                            "carousel",
                            {
                              attrs: {
                                perPage: 4,
                              },
                            },
                            _vm._l(_vm.exampleData, function (item) {
                              return _c("slide", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "p-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 slide-card d-flex flex-column align-items-center py-4 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-75",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ratio ratio-1x1",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "w-100 h-100",
                                                  attrs: {
                                                    src: item.image,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "slide-card-title text-lioshutan my-3",
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "area-text m-0",
                                          },
                                          [
                                            _vm._v(_vm._s(item.nationality)),
                                            _c("img", {
                                              staticClass:
                                                "ml-1 nationality-img",
                                              attrs: {
                                                src: item.nationalityImg,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "w-100 px-3",
                                          },
                                          [_c("hr")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "slide-card-btn btn text-decoration-none my-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.test(
                                                  item.introduction
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v("導師介紹"),
                                            _c("font-awesome-icon", {
                                              staticClass: "ml-1",
                                              attrs: {
                                                icon: "video",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "d-flex justify-content-center",
      },
      [
        _c(
          "p",
          {
            staticClass: "area-english-title text-lioshutan",
          },
          [_vm._v(":: Consultants ::")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }