var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("web-socket-info-modal", {
    ref: "web-socket-info-modal",
    attrs: {
      socketData: _vm.socketData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }