var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "ul-personal-menu",
    },
    [
      _c(
        "p",
        {
          staticClass: "txt-personal-menu-title",
          staticStyle: {
            cursor: "pointer",
          },
          on: {
            mouseover: function ($event) {
              return _vm.switchGiveBackInfo()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("contract.feedback_record")))]
      ),
      _vm.show_give_back_info === true
        ? _c(
            "ul",
            {
              staticClass: "px-4 mb-1 mt-3",
            },
            [
              _c(
                "p",
                {
                  staticClass: "give-back-info",
                },
                [
                  _vm._v(_vm._s(_vm.$t("contract.extra_lessons")) + ":"),
                  _c(
                    "span",
                    {
                      staticClass: "float-right mr-2",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.vip_account_info.feedback_quota.amount) +
                          " " +
                          _vm._s(_vm.$t("class.class"))
                      ),
                    ]
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(_vm._s(_vm.$t("contract.extra_deadline")) + ":"),
                  _c(
                    "span",
                    {
                      staticClass: "float-right mr-2",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.vip_account_info.feedback_quota.month_amount
                        ) +
                          " " +
                          _vm._s(_vm.$t("month")) +
                          " " +
                          _vm._s(
                            _vm.vip_account_info.feedback_quota.day_amount
                          ) +
                          _vm._s(_vm.$t("contract.day"))
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }