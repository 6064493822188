var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "position-absolute",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-block d-md-none",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "fixed-top vh-100 vw-100 d-flex justify-content-center align-items-center",
                  attrs: {
                    id: "login-container",
                  },
                },
                [
                  _c("div", {
                    staticClass: "position-absolute vw-100 vh-100",
                    on: {
                      click: _vm.closeModal,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "login-modal rounded-7 position-relative",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-center",
                        },
                        [
                          _vm._m(0),
                          _c("img", {
                            staticClass: "w-100 rounded-top-7",
                            attrs: {
                              src: _vm.file.parentLoginPic,
                            },
                          }),
                        ]
                      ),
                      _vm._m(1),
                      _c(
                        "div",
                        {
                          staticClass: "mx-4",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "input-group mb-3",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group-prepend",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "input-group-text h-100",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "user",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.loginForm.account,
                                    expression: "loginForm.account",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeholder: "輸入信箱帳號",
                                },
                                domProps: {
                                  value: _vm.loginForm.account,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.loginForm,
                                      "account",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "input-group mb-3",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group-prepend",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "input-group-text h-100",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "key",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.passwordType === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "密碼",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.loginForm.password
                                      )
                                        ? _vm._i(_vm.loginForm.password, null) >
                                          -1
                                        : _vm.loginForm.password,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.loginForm.password,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  })
                                : _vm.passwordType === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "密碼",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.loginForm.password,
                                        null
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.loginForm,
                                          "password",
                                          null
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "密碼",
                                      type: _vm.passwordType,
                                    },
                                    domProps: {
                                      value: _vm.loginForm.password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.loginForm,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                              _c("font-awesome-icon", {
                                staticClass:
                                  "showPasswordIcon position-absolute",
                                attrs: {
                                  icon: _vm.showPasswordIconName,
                                },
                                on: {
                                  click: _vm.togglePasswordVisibility,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-end mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "jr-home-main-color",
                                  on: {
                                    click: _vm.openForgotPasswordModal,
                                  },
                                },
                                [_vm._v("忘記密碼？")]
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-block border-0 rounded btn-primary text-light py-2 mb-3",
                              attrs: {
                                disabled: _vm.logining,
                              },
                              on: {
                                click: _vm.login,
                              },
                            },
                            [
                              !_vm.logining
                                ? _c("span", [_vm._v("立即登入")])
                                : _vm.logining
                                ? _c(
                                    "div",
                                    [
                                      _c("b-spinner", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          small: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("登入中...")]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-link btn-block text-center border-0 p-0 jr-home-main-color",
                              on: {
                                click: _vm.openRegisterParentAccountModal,
                              },
                            },
                            [_vm._v("還沒有家長帳號？")]
                          ),
                          _c("i", {
                            staticClass:
                              "showPasswordIcon position-absolute fa fa-plus",
                            attrs: {
                              "data-v-3cb02076": "",
                              id: "showPassword",
                              "aria-hidden": "true",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-none d-md-block d-lg-none",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "fixed-top vh-100 vw-100 d-flex justify-content-center align-items-center",
                  attrs: {
                    id: "login-container",
                  },
                },
                [
                  _c("div", {
                    staticClass: "position-absolute vw-100 vh-100",
                    on: {
                      click: _vm.closeModal,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "login-modal rounded-7 position-relative",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "login-modal-top text-center position-relative",
                        },
                        [
                          _c("img", {
                            staticClass: "w-100 h-100 rounded-top-7",
                            attrs: {
                              src: _vm.file.parentLoginPic,
                            },
                          }),
                          _vm._m(2),
                        ]
                      ),
                      _vm._m(3),
                      _c(
                        "div",
                        {
                          staticClass: "mx-auto w-50",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "input-group mb-3",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group-prepend",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "input-group-text h-100",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "user",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.loginForm.account,
                                    expression: "loginForm.account",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeholder: "輸入信箱帳號",
                                },
                                domProps: {
                                  value: _vm.loginForm.account,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.loginForm,
                                      "account",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "input-group mb-3",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group-prepend",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "input-group-text h-100",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "key",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.passwordType === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "密碼",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.loginForm.password
                                      )
                                        ? _vm._i(_vm.loginForm.password, null) >
                                          -1
                                        : _vm.loginForm.password,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.loginForm.password,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  })
                                : _vm.passwordType === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "密碼",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.loginForm.password,
                                        null
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.loginForm,
                                          "password",
                                          null
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "密碼",
                                      type: _vm.passwordType,
                                    },
                                    domProps: {
                                      value: _vm.loginForm.password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.loginForm,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                              _c("font-awesome-icon", {
                                staticClass:
                                  "showPasswordIcon position-absolute",
                                attrs: {
                                  icon: _vm.showPasswordIconName,
                                },
                                on: {
                                  click: _vm.togglePasswordVisibility,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-end mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "jr-home-main-color",
                                  on: {
                                    click: _vm.openForgotPasswordModal,
                                  },
                                },
                                [_vm._v("忘記密碼？")]
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-block border-0 rounded btn-primary text-light py-2 mb-3",
                              attrs: {
                                disabled: _vm.logining,
                              },
                              on: {
                                click: _vm.login,
                              },
                            },
                            [
                              !_vm.logining
                                ? _c("span", [_vm._v("立即登入")])
                                : _vm.logining
                                ? _c(
                                    "div",
                                    [
                                      _c("b-spinner", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          small: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("登入中...")]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "jr-home-main-color btn btn-link btn-block text-center border-0 p-0",
                              on: {
                                click: _vm.openRegisterParentAccountModal,
                              },
                            },
                            [_vm._v("還沒有家長帳號？")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-none d-md-none d-lg-block",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "fixed-top vh-100 vw-100 d-flex justify-content-center align-items-center",
                  attrs: {
                    id: "login-container",
                  },
                },
                [
                  _c("div", {
                    staticClass: "position-absolute vw-100 vh-100 z-index-1",
                    on: {
                      click: _vm.closeModal,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "login-modal rounded-7 d-flex",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-100 w-65 rounded-left-7 position-relative",
                        },
                        [
                          _c("img", {
                            staticClass: "w-100 h-100 rounded-left-7",
                            attrs: {
                              src: _vm.file.parentLoginPic,
                            },
                          }),
                          _vm._m(4),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "h-100 w-35 px-3 position-relative",
                        },
                        [
                          _vm._m(5),
                          _c(
                            "div",
                            {
                              staticClass: "mt-5 w-75 mx-auto",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group mb-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group-prepend",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "input-group-text h-100",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "user",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.account,
                                        expression: "loginForm.account",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "輸入信箱帳號",
                                    },
                                    domProps: {
                                      value: _vm.loginForm.account,
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.login.apply(null, arguments)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.loginForm,
                                          "account",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "input-group mb-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group-prepend",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "input-group-text h-100",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "key",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.passwordType === "checkbox"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.loginForm.password,
                                            expression: "loginForm.password",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "密碼",
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.loginForm.password
                                          )
                                            ? _vm._i(
                                                _vm.loginForm.password,
                                                null
                                              ) > -1
                                            : _vm.loginForm.password,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.login.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.loginForm.password,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.loginForm,
                                                    "password",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.loginForm,
                                                    "password",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      })
                                    : _vm.passwordType === "radio"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.loginForm.password,
                                            expression: "loginForm.password",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "密碼",
                                          type: "radio",
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.loginForm.password,
                                            null
                                          ),
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.login.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.loginForm,
                                              "password",
                                              null
                                            )
                                          },
                                        },
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.loginForm.password,
                                            expression: "loginForm.password",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "密碼",
                                          type: _vm.passwordType,
                                        },
                                        domProps: {
                                          value: _vm.loginForm.password,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.login.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.loginForm,
                                              "password",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                  _c("font-awesome-icon", {
                                    staticClass:
                                      "showPasswordIcon position-absolute",
                                    attrs: {
                                      icon: _vm.showPasswordIconName,
                                    },
                                    on: {
                                      click: _vm.togglePasswordVisibility,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mb-3",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "jr-home-main-color forget-btn",
                                      on: {
                                        click: _vm.openForgotPasswordModal,
                                      },
                                    },
                                    [_vm._v("忘記密碼？")]
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn-block border-0 rounded btn-primary text-light py-2 mb-3 mt-5",
                                  attrs: {
                                    disabled: _vm.logining,
                                  },
                                  on: {
                                    click: _vm.login,
                                  },
                                },
                                [
                                  !_vm.logining
                                    ? _c("span", [_vm._v("立即登入")])
                                    : _vm.logining
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-spinner", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                            },
                                          }),
                                          _c("span", [_vm._v("登入中...")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-link btn-block jr-home-main-color text-center border-0 p-0",
                                  on: {
                                    click: _vm.openRegisterParentAccountModal,
                                  },
                                },
                                [_vm._v("還沒有家長帳號？")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "w-100 h-25 gradient-bg position-absolute text-center",
        attrs: {
          id: "login-content",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "text-light position-absolute",
          },
          [_vm._v("從雲端出發，任何時間、任何地點都能上課")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "text-center mt-4",
      },
      [
        _c(
          "h3",
          {
            staticClass: "jr-home-main-color my-3",
          },
          [_vm._v("家長登入")]
        ),
        _c("p", [_vm._v("登入 WUWOW Jr 打開世界的一扇窗！")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "w-100 h-50 gradient-bg position-absolute text-center",
        attrs: {
          id: "login-content",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "text-light mb-3 h5",
          },
          [_vm._v("從雲端出發，任何時間、任何地點都能上課")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "text-center mt-4",
      },
      [
        _c(
          "h3",
          {
            staticClass: "jr-home-main-color my-3",
          },
          [_vm._v("家長登入")]
        ),
        _c("p", [_vm._v("登入 WUWOW Jr 打開世界的一扇窗！")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "gradient-bg h-25 w-100 position-absolute fixed-bottom text-center rounded-bottom-left desktop",
        attrs: {
          id: "login-content",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "text-light h5",
          },
          [_vm._v("從雲端出發，任何時間、任何地點都能上課")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "text-center mt-5",
      },
      [
        _c(
          "h3",
          {
            staticClass: "jr-home-main-color mt-3 mb-4",
          },
          [_vm._v("家長登入")]
        ),
        _c("p", [_vm._v("登入 WUWOW Jr 打開世界的一扇窗！")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }