var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _vm.role !== "parent"
      ? _c(
          "p",
          {
            staticClass: "txt-personal-menu-title",
          },
          [_vm._v(_vm._s(_vm.$t("my")) + _vm._s(_vm.$t("account")))]
        )
      : _vm._e(),
    _c(
      "ul",
      {
        staticClass: "ul-personal-menu",
      },
      [
        _vm._l(_vm.filterAccountList, function (item) {
          return _vm.role === "parent"
            ? _c(
                "li",
                {
                  key: item.name,
                  staticClass: "li-personal-menu",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "lnk-personal-menu",
                      attrs: {
                        to: {
                          path: item.path,
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("layout.parent_" + item.name))),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e()
        }),
        _vm._l(_vm.accountLists, function (item) {
          return _vm.role === "student"
            ? _c(
                "li",
                {
                  key: item.name,
                  staticClass: "li-personal-menu",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "lnk-personal-menu",
                      attrs: {
                        to: {
                          path: item.path,
                        },
                        target: item.target === "blank" ? "_blank" : null,
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("layout." + item.name))),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }