var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      attrs: {
        id: "app",
      },
    },
    [
      _vm.currentPage === _vm.hideLayoutPage.home
        ? _c("home")
        : _vm.currentPage === _vm.hideLayoutPage.parentPage
        ? _c("parent")
        : _vm.currentPage === _vm.hideLayoutPage.thankYouPage
        ? _c("thank-you")
        : _vm.currentPage === _vm.hideLayoutPage.login
        ? _c("login")
        : _vm.currentPage === _vm.hideLayoutPage.registerParentAccount
        ? _c("router-view")
        : _vm.currentPage.split("/").includes("news")
        ? _c("router-view")
        : _vm.currentPage.split("/").includes("event")
        ? _c("router-view")
        : _vm.currentPage.split("/").includes("program-contract")
        ? _c("router-view")
        : _vm.currentPage.split("/").includes("reviews")
        ? _c("router-view")
        : _c("layout"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }