var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "reserve-class",
      style: `    background-image: url(${_vm.file.girlBG});    background-repeat: no-repeat;    background-position: center;    background-size: cover;  `,
      attrs: {
        id: "reserve-class",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-block d-md-none",
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "mx-1",
            },
            [
              _c(
                "div",
                {
                  staticClass: "from-class position-relative mx-3 p-4",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.studentChineseName,
                            expression: "formdata.studentChineseName",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "孩子姓名",
                        },
                        domProps: {
                          value: _vm.formdata.studentChineseName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "studentChineseName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.studentEnglishName,
                            expression: "formdata.studentEnglishName",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "孩子英文名稱",
                        },
                        domProps: {
                          value: _vm.formdata.studentEnglishName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "studentEnglishName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.studentAge,
                            expression: "formdata.studentAge",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "孩子年齡",
                          type: "number",
                        },
                        domProps: {
                          value: _vm.formdata.studentAge,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "studentAge",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.parentName,
                            expression: "formdata.parentName",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "家長姓名",
                        },
                        domProps: {
                          value: _vm.formdata.parentName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "parentName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.parentPhone,
                            expression: "formdata.parentPhone",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "家長電話",
                        },
                        domProps: {
                          value: _vm.formdata.parentPhone,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "parentPhone",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.parentEmail,
                            expression: "formdata.parentEmail",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "家長email",
                        },
                        domProps: {
                          value: _vm.formdata.parentEmail,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "parentEmail",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "form-check-box mb-3",
                    },
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "2",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.privacyPolicyCheck,
                                expression: "formdata.privacyPolicyCheck",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              id: "privacyPolicyCheck",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.formdata.privacyPolicyCheck
                              )
                                ? _vm._i(
                                    _vm.formdata.privacyPolicyCheck,
                                    null
                                  ) > -1
                                : _vm.formdata.privacyPolicyCheck,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.formdata.privacyPolicyCheck,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formdata,
                                        "privacyPolicyCheck",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formdata,
                                        "privacyPolicyCheck",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.formdata,
                                    "privacyPolicyCheck",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "10",
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                            },
                            [
                              _vm._v("我同意WUWOW線上英文"),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.privacyUrl,
                                    target: "_blank",
                                    for: "privacyPolicyCheck",
                                  },
                                },
                                [_vm._v("隱私權政策")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "text-light form-btn border-0 rounded-pill py-2 px-4 mt-3",
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("預約試聽")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-none d-md-block d-lg-none",
        },
        [
          _vm._m(1),
          _c(
            "div",
            {
              staticClass: "mx-1",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-75 from-class position-relative mx-3 p-4 mx-auto",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.studentChineseName,
                            expression: "formdata.studentChineseName",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "孩子姓名",
                        },
                        domProps: {
                          value: _vm.formdata.studentChineseName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "studentChineseName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.studentEnglishName,
                            expression: "formdata.studentEnglishName",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "孩子英文名稱",
                        },
                        domProps: {
                          value: _vm.formdata.studentEnglishName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "studentEnglishName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.studentAge,
                            expression: "formdata.studentAge",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "孩子年齡",
                          type: "number",
                        },
                        domProps: {
                          value: _vm.formdata.studentAge,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "studentAge",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.parentName,
                            expression: "formdata.parentName",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "家長姓名",
                        },
                        domProps: {
                          value: _vm.formdata.parentName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "parentName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.parentPhone,
                            expression: "formdata.parentPhone",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "家長電話",
                        },
                        domProps: {
                          value: _vm.formdata.parentPhone,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "parentPhone",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center my-5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formdata.parentEmail,
                            expression: "formdata.parentEmail",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "家長email",
                        },
                        domProps: {
                          value: _vm.formdata.parentEmail,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formdata,
                              "parentEmail",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "form-check-box mb-3",
                    },
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "1",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.privacyPolicyCheck,
                                expression: "formdata.privacyPolicyCheck",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              id: "privacyPolicyCheck",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.formdata.privacyPolicyCheck
                              )
                                ? _vm._i(
                                    _vm.formdata.privacyPolicyCheck,
                                    null
                                  ) > -1
                                : _vm.formdata.privacyPolicyCheck,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.formdata.privacyPolicyCheck,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formdata,
                                        "privacyPolicyCheck",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formdata,
                                        "privacyPolicyCheck",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.formdata,
                                    "privacyPolicyCheck",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "11",
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                            },
                            [
                              _vm._v("我同意WUWOW線上英文"),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.privacyUrl,
                                    target: "_blank",
                                    for: "privacyPolicyCheck",
                                  },
                                },
                                [_vm._v("隱私權政策")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "text-light form-btn border-0 rounded-pill py-1 px-3 mt-3",
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("預約試聽")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-none d-md-none d-lg-block",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row justify-content-center align-items-center py-5",
            },
            [
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass: "behind-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "behind-card-content position-relative mx-3",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "m-0 mr-2",
                            },
                            [_vm._v("孩子姓名")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.studentChineseName,
                                expression: "formdata.studentChineseName",
                              },
                            ],
                            domProps: {
                              value: _vm.formdata.studentChineseName,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formdata,
                                  "studentChineseName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "m-0 mr-2",
                            },
                            [_vm._v("孩子英文名稱")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.studentEnglishName,
                                expression: "formdata.studentEnglishName",
                              },
                            ],
                            domProps: {
                              value: _vm.formdata.studentEnglishName,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formdata,
                                  "studentEnglishName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "m-0 mr-2",
                            },
                            [_vm._v("孩子年齡")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.studentAge,
                                expression: "formdata.studentAge",
                              },
                            ],
                            attrs: {
                              type: "number",
                            },
                            domProps: {
                              value: _vm.formdata.studentAge,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formdata,
                                  "studentAge",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "m-0 mr-2",
                            },
                            [_vm._v("家長姓名")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.parentName,
                                expression: "formdata.parentName",
                              },
                            ],
                            domProps: {
                              value: _vm.formdata.parentName,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formdata,
                                  "parentName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "m-0 mr-2",
                            },
                            [_vm._v("家長電話")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.parentPhone,
                                expression: "formdata.parentPhone",
                              },
                            ],
                            domProps: {
                              value: _vm.formdata.parentPhone,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formdata,
                                  "parentPhone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "m-0 mr-2",
                            },
                            [_vm._v("家長email")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formdata.parentEmail,
                                expression: "formdata.parentEmail",
                              },
                            ],
                            domProps: {
                              value: _vm.formdata.parentEmail,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formdata,
                                  "parentEmail",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "b-row",
                        {
                          staticClass: "form-check-box mt-5",
                        },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "2",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formdata.privacyPolicyCheck,
                                    expression: "formdata.privacyPolicyCheck",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "privacyPolicyCheck",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.formdata.privacyPolicyCheck
                                  )
                                    ? _vm._i(
                                        _vm.formdata.privacyPolicyCheck,
                                        null
                                      ) > -1
                                    : _vm.formdata.privacyPolicyCheck,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.formdata.privacyPolicyCheck,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.formdata,
                                            "privacyPolicyCheck",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.formdata,
                                            "privacyPolicyCheck",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.formdata,
                                        "privacyPolicyCheck",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "10",
                              },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                },
                                [
                                  _vm._v("我同意WUWOW線上英文"),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.privacyUrl,
                                        target: "_blank",
                                        for: "privacyPolicyCheck",
                                      },
                                    },
                                    [_vm._v("隱私權政策")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "send-btn btn form-btn rounded-pill px-3",
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm()
                                },
                              },
                            },
                            [_vm._v("預約試聽")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "text-center mx-5 text-lioshutan",
      },
      [
        _c("p", [_vm._v(":: Let's start ::")]),
        _c("h3", [_vm._v("免費為孩子")]),
        _c("h3", [_vm._v("預約一堂試聽課程")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-row justify-content-center align-items-center py-5",
      },
      [
        _c(
          "div",
          {
            staticClass: "mx-5 text-lioshutan",
            staticStyle: {
              width: "350px",
            },
          },
          [
            _c(
              "p",
              {
                staticClass: "area-english-title",
              },
              [_vm._v(":: Let's start ::")]
            ),
            _c("h1", [_vm._v("免費為孩子")]),
            _c("h1", [_vm._v("預約一堂試聽課程")]),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "mx-5 text-lioshutan",
        staticStyle: {
          width: "30%",
          "margin-bottom": "220px",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "area-english-title",
          },
          [_vm._v(":: Let's start ::")]
        ),
        _c(
          "h1",
          {
            staticClass: "reserve-title",
          },
          [_vm._v("免費為孩子")]
        ),
        _c(
          "h1",
          {
            staticClass: "reserve-title",
          },
          [_vm._v("預約一堂試聽課程")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }