var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "footer",
    {
      staticClass: "lay-footer",
    },
    [
      _c(
        "div",
        {
          staticClass: "container lay-footer-container",
        },
        [
          _vm.role === "student" || _vm.role === "parent"
            ? _c(
                "div",
                {
                  staticClass: "row reg-footer-nav",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-4 are-footer-company",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "panel-footer-logo",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "lnk-footer-logo",
                              attrs: {
                                href: _vm.wuwow.url,
                                title: _vm.wuwow.name,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "w-75",
                                attrs: {
                                  src: _vm.wuwow.img_url,
                                  alt: _vm.wuwow.name,
                                  title: _vm.wuwow.name,
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "txt-footer-address",
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.company_info.address),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "txt-footer-contact",
                        },
                        [
                          _vm._v("email:"),
                          _c("span", {
                            staticClass: "ml-1",
                            domProps: {
                              innerHTML: _vm._s(_vm.company_info.email),
                            },
                          }),
                          _c("br"),
                          _vm._v("contact:"),
                          _c(
                            "a",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                href: "tel:" + _vm.company_info.phone,
                              },
                            },
                            [_vm._v(_vm._s(_vm.company_info.phone))]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "nav",
                    {
                      staticClass: "col-12 col-md-8 are-footer-menu p-0",
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "ul-footer-menu",
                        },
                        _vm._l(_vm.footer_nav, function (nav) {
                          return _c(
                            "li",
                            {
                              key: nav.name,
                              staticClass: "li-footer-menu",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "txt-footer-menu",
                                },
                                [_vm._v(_vm._s(_vm.$t(nav.name)))]
                              ),
                              _c(
                                "ul",
                                {
                                  staticClass: "ul-footer-submenu",
                                },
                                _vm._l(nav.list, function (list) {
                                  return _c(
                                    "li",
                                    {
                                      key: list.name,
                                      staticClass: "li-footer-submenu",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "lnk-footer-submenu",
                                          attrs: {
                                            to: {
                                              path: list.url,
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(list.name)))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._m(0),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "row reg-footer-copyright",
      },
      [
        _c(
          "div",
          {
            staticClass: "are-footer-copyright",
          },
          [
            _vm._v(
              "Copyright © 2017 LIOSHUTAN Digital Learning, Inc. All rights reserved."
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "are-footer-link",
          },
          [
            _c(
              "a",
              {
                staticClass: "lnk-footer-link",
                attrs: {
                  href: "/privacy",
                  target: "_blank",
                },
              },
              [_vm._v("隱私權政策")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }