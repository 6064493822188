var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      ref: _vm.modalRef,
      attrs: {
        id: "webSocketInfoModal",
        title: _vm.$t("infoModalTitle"),
        "ok-title": _vm.$t("enter"),
        "cancel-title": _vm.$t("cancel"),
        centered: "",
        "header-bg-variant": "primary",
      },
      on: {
        ok: _vm.accessClassroom,
      },
    },
    [
      _c(
        "h4",
        {
          staticClass: "text-center",
        },
        [
          _vm._v(_vm._s(_vm.$t("enterClassNotice"))),
          _c("font-awesome-icon", {
            staticClass: "text-warning ml-2",
            attrs: {
              icon: "lightbulb",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }