// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/bg-children-day-mobile.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.from-class[data-v-3edd19a5] {\n  background-color: white;\n  box-shadow: 0px 3px 6px #00000029;\n  border-radius: 10px;\n  color: #393939;\n  opacity: 0.7;\n}\n.reserve-class[data-v-3edd19a5] {\n  padding: 150px 0;\n}\n.reserve-class .reserve-title[data-v-3edd19a5] {\n    font-size: 50px;\n}\n.reserve-class .subtitle[data-v-3edd19a5] {\n    font-size: 20px;\n    letter-spacing: 4px;\n}\n.behind-card-content input[data-v-3edd19a5] {\n  width: 300px;\n}\n.behind-card-content .form-check-input[data-v-3edd19a5] {\n  width: 20px;\n  height: 20px;\n  right: 10px;\n}\n.behind-card-content .send-btn[data-v-3edd19a5] {\n  color: white;\n  font-size: 20px;\n  box-shadow: 0px 3px 6px #00000029;\n  margin-top: 30px;\n}\n.behind-card-content .send-btn[data-v-3edd19a5]:hover {\n    background-color: #3DA0E8;\n    color: white;\n}\n@media screen and (max-width: 1600px) {\n.reserve-class .reserve-title[data-v-3edd19a5] {\n    font-size: 40px;\n}\n}\n@media screen and (max-width: 1200px) {\n.behind-card-content[data-v-3edd19a5] {\n    width: 500px;\n}\n.behind-card-content input[data-v-3edd19a5] {\n      width: 200px;\n}\n.form-check-box[data-v-3edd19a5] {\n    text-align: left;\n}\n.form-check-box .form-check-input[data-v-3edd19a5] {\n      right: 5px;\n}\n.reserve-class .reserve-title[data-v-3edd19a5] {\n    font-size: 35px;\n}\n}\n@media screen and (max-width: 414px) {\n.subtitle[data-v-3edd19a5] {\n    font-size: 15px;\n    letter-spacing: 3px;\n}\n}\n\n/* TODO 填單區域兒童節排版 */\n#reserve-class[data-v-3edd19a5] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n#reserve-class .children-day[data-v-3edd19a5] {\n    width: 100%;\n    margin-bottom: 0;\n}\n@media (min-width: 992px) {\n#reserve-class[data-v-3edd19a5] {\n      background-image: url(\"https://cdn3.wuwow.tw/arts/events/bg-children-day-desktop.png\");\n}\n}\n", ""]);
// Exports
module.exports = exports;
