var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "main",
    {
      staticClass: "main",
    },
    [
      _c(
        "div",
        {
          staticClass: "main-box",
          attrs: {
            id: "一對一教學課程實錄",
          },
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "main-content",
            },
            [
              _c(
                "div",
                {
                  staticClass: "main-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "main-center-content",
                    },
                    [
                      _c("img", {
                        staticClass: "main-center-img",
                        attrs: {
                          src: require("@/assets/trial/courseRecordVideo.png"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.youtubeModal(_vm.courseRecord)
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("youtube-modal", {
        attrs: {
          src: _vm.youtubeUrl,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "class-title",
      },
      [
        _c(
          "span",
          {
            staticClass: "first-title",
          },
          [
            _vm._v("::Class Recording ::"),
            _c("br"),
            _vm._v("一對一教學課程實錄"),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }