var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "feature",
      attrs: {
        id: "feature",
      },
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "feature-content home-container",
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "circle col-12 col-lg-5 ratio ratio-1x1 d-flex justify-content-center align-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ratio ratio-1x1 text-center w-75",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "circle-icon d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("img", {
                            staticClass: "h-75",
                            attrs: {
                              src: _vm.file.calendarSVG,
                            },
                          }),
                        ]
                      ),
                      _vm._m(1),
                      _vm._m(2),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "circle col-12 col-lg-5 ratio ratio-1x1 d-flex justify-content-center align-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ratio ratio-1x1 text-center w-75",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "circle-icon d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("img", {
                            staticClass: "w-100 h-100",
                            attrs: {
                              src: _vm.file.classSVG,
                            },
                          }),
                        ]
                      ),
                      _vm._m(3),
                      _vm._m(4),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "circle col-12 col-lg-5 ratio ratio-1x1 d-flex justify-content-center align-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ratio ratio-1x1 text-center w-75",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "circle-icon d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("img", {
                            staticClass: "w-100 h-100",
                            attrs: {
                              src: _vm.file.clockSVG,
                            },
                          }),
                        ]
                      ),
                      _vm._m(5),
                      _vm._m(6),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "circle col-12 col-lg-5 ratio ratio-1x1 d-flex justify-content-center align-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ratio ratio-1x1 text-center w-75",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "circle-icon d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("img", {
                            staticClass: "w-100 h-100",
                            attrs: {
                              src: _vm.file.serviceSVG,
                            },
                          }),
                        ]
                      ),
                      _vm._m(7),
                      _vm._m(8),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "text-center mb-5",
      },
      [
        _c(
          "p",
          {
            staticClass: "area-english-title text-light",
          },
          [_vm._v(":: Features ::")]
        ),
        _c(
          "h2",
          {
            staticClass: "area-title text-light",
          },
          [_vm._v("WUWOW Jr 學習優勢")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "circle-title d-flex justify-content-center align-items-end",
      },
      [
        _c(
          "h3",
          {
            staticClass: "text-lioshutan mb-0",
          },
          [_vm._v("最高彈性的訂課方針")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "circle-text pt-2",
      },
      [
        _c(
          "p",
          {
            staticClass: "pt-0",
          },
          [
            _vm._v(
              "不論是想定期安排課程，或即時挑選時間，我們提供最高彈性的排課自由，更可於課前15分鐘免費取消。"
            ),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "circle-title d-flex justify-content-center align-items-end",
      },
      [
        _c(
          "h3",
          {
            staticClass: "text-lioshutan mb-0",
          },
          [_vm._v("全真人１對１教學")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "circle-text pt-2",
      },
      [
        _c(
          "p",
          {
            staticClass: "pt-0",
          },
          [
            _vm._v(
              "全課程皆採 1對1 教學，牢牢抓住孩子注意力，讓學習的每一分鐘都能夠得到效果。"
            ),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "circle-title d-flex justify-content-center align-items-end",
      },
      [
        _c(
          "h3",
          {
            staticClass: "text-lioshutan mb-0",
          },
          [_vm._v("25 分鐘無壓學習")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "circle-text pt-2",
      },
      [
        _c(
          "p",
          {
            staticClass: "pt-0",
          },
          [
            _vm._v(
              "把握 25 分鐘黃金注意力時間，讓孩子在沒有負擔的學習中培養出興趣。比起一次學很多，我們更希望孩子能學得長久。"
            ),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass:
          "circle-title d-flex justify-content-center align-items-end",
      },
      [
        _c(
          "h3",
          {
            staticClass: "text-lioshutan mb-0",
          },
          [_vm._v("最即時的真人客服")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "circle-text pt-2",
      },
      [
        _c(
          "p",
          {
            staticClass: "pt-0",
          },
          [
            _vm._v(
              "好評價來自於我們的用心服務。每堂課程進行時，我們的客服人員將在一旁待命，隨時為您處理所有疑難雜症。"
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }