var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      attrs: {
        id: "material",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-block d-md-none",
        },
        [
          _c(
            "div",
            {
              staticClass: "home-container mx-auto mt-5 col-md-10 mt-100",
            },
            [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "row justify-content-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-3 border material-shadow material-rounded mx-4 bg-light card-mobile",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design1,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "area-title text-lioshutan text-center",
                                },
                                [_vm._v("ARCS 教學流程")]
                              ),
                              _vm._m(1),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-3 border material-shadow material-rounded mx-4 bg-light card-mobile",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design2,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "area-title text-lioshutan text-center",
                                },
                                [_vm._v("貼近孩子的教材設計")]
                              ),
                              _vm._m(2),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-3 border material-shadow material-rounded mx-4 bg-light card-mobile",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design3,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "area-title text-lioshutan text-center",
                                },
                                [_vm._v("0 基礎也不擔心")]
                              ),
                              _vm._m(3),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-none d-md-block d-lg-none",
        },
        [
          _c(
            "div",
            {
              staticClass: "home-container mx-auto mt-5 mt-100 p-3",
            },
            [
              _vm._m(4),
              _c(
                "div",
                {
                  staticClass: "row justify-content-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-8 border material-shadow material-rounded mx-4 bg-light card-mobile",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design1,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h2",
                                {
                                  staticClass: "text-lioshutan text-center",
                                },
                                [_vm._v("ARCS 教學流程")]
                              ),
                              _vm._m(5),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-8 border material-shadow material-rounded mx-4 bg-light card-mobile",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design2,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h2",
                                {
                                  staticClass: "text-lioshutan text-center",
                                },
                                [_vm._v("貼近孩子的教材設計")]
                              ),
                              _vm._m(6),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-8 border material-shadow material-rounded mx-4 bg-light card-mobile",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design3,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h2",
                                {
                                  staticClass: "text-lioshutan text-center",
                                },
                                [_vm._v("0 基礎也不擔心")]
                              ),
                              _vm._m(7),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-none d-md-none d-lg-block home-container",
        },
        [
          _c(
            "div",
            {
              staticClass: "mx-auto mt-5 col-12 mt-100",
            },
            [
              _vm._m(8),
              _c(
                "div",
                {
                  staticClass: "row justify-content-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-4 material-rounded my-5 px-3",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-content ratio-1x1 bg-light border material-shadow",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design1,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "area-content-title text-lioshutan text-center",
                                },
                                [_vm._v("ARCS 教學流程")]
                              ),
                              _vm._m(9),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-4 material-rounded my-5 px-3",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-content ratio-1x1 bg-light border material-shadow",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design2,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "area-content-title text-lioshutan text-center",
                                },
                                [_vm._v("貼近孩子的教材設計")]
                              ),
                              _vm._m(10),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-4 material-rounded my-5 px-3",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-content ratio-1x1 bg-light border material-shadow",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body p-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      src: _vm.file.design3,
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "area-content-title text-lioshutan text-center",
                                },
                                [_vm._v("0 基礎也不擔心")]
                              ),
                              _vm._m(11),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "d-flex justify-content-center my-5",
      },
      [
        _c(
          "h3",
          {
            staticClass:
              "d-inline-block bg-light text-lioshutan rounded-pill px-4 py-2 mb-5",
          },
          [_vm._v("專屬台灣學子設計")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle",
      },
      [
        _c("p", [
          _vm._v(
            "為了讓孩子在線上能學出興趣，我們採用 ARCS 動機學習理論設計教學，專注於建立孩子學習的成就感與熱忱。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle",
      },
      [
        _c("p", [
          _vm._v(
            "WUWOW Jr 教材內容皆選自於孩子生活貼近的事物，避開艱難生僻的題材，快速引起學習的興趣。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle",
      },
      [
        _c("p", [
          _vm._v(
            "透過 TPR 的互動教學模式，搭配專為零基礎學生設計的 Pre-class 教材，即使是 ABC 都不熟悉的孩子，也能在全英文的環境下展開學習。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "col-md-12 d-flex justify-content-center my-5",
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "d-inline-block bg-light text-lioshutan rounded-pill px-4 py-2",
          },
          [_vm._v("專屬台灣學子設計")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle",
      },
      [
        _c("p", [
          _vm._v(
            "為了讓孩子在線上能學出興趣，我們採用 ARCS 動機學習理論設計教學，專注於建立孩子學習的成就感與熱忱。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle",
      },
      [
        _c("p", [
          _vm._v(
            "WUWOW Jr 教材內容皆選自於孩子生活貼近的事物，避開艱難生僻的題材，快速引起學習的興趣。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle",
      },
      [
        _c("p", [
          _vm._v(
            "透過 TPR 的互動教學模式，搭配專為零基礎學生設計的 Pre-class 教材，即使是 ABC 都不熟悉的孩子，也能在全英文的環境下展開學習。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "d-flex justify-content-center my-5 py-5",
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "area-title d-inline-block bg-light text-lioshutan rounded-pill px-4 py-2",
          },
          [_vm._v("專屬台灣學子設計")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle area-text",
      },
      [
        _c("p", [
          _vm._v(
            "為了讓孩子在線上能學出興趣，我們採用 ARCS 動機學習理論設計教學，專注於建立孩子學習的成就感與熱忱。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle area-text",
      },
      [
        _c("p", [
          _vm._v(
            "WUWOW Jr 教材內容皆選自於孩子生活貼近的事物，避開艱難生僻的題材，快速引起學習的興趣。"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "card-subtitle area-text",
      },
      [
        _c("p", [
          _vm._v(
            "透過 TPR 的互動教學模式，搭配專為零基礎學生設計的 Pre-class 教材，即使是 ABC 都不熟悉的孩子，也能在全英文的環境下展開學習。"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }