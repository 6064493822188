var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "qa-class pt-4",
    },
    [
      _c(
        "div",
        {
          staticClass: "container",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column align-items-center py-5",
            },
            [
              _c(
                "p",
                {
                  staticClass: "area-english-title text-lioshutan",
                },
                [_vm._v(":: FAQ ::")]
              ),
              _c(
                "h2",
                {
                  staticClass: "area-title text-lioshutan mb-4",
                },
                [_vm._v("常見 Q&A")]
              ),
              _vm._l(_vm.qa, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `mobile_${index}`,
                    staticClass: "d-block d-md-none w-100 my-2",
                    on: {
                      click: function ($event) {
                        _vm.qa[index].visible = !_vm.qa[index].visible
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "question-content p-4",
                        class: item.visible ? "question-content-show" : "",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("div", [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "question-title d-inline mx-1 text-lioshutan",
                                },
                                [_vm._v(_vm._s(`Q${index + 1}.`))]
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass: "question-title d-inline mx-1",
                                },
                                [_vm._v(_vm._s(item.question))]
                              ),
                            ]),
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: `caret-${
                                  _vm.qa[index].visible ? "up" : "down"
                                }`,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            model: {
                              value: item.visible,
                              callback: function ($$v) {
                                _vm.$set(item, "visible", $$v)
                              },
                              expression: "item.visible",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mt-4",
                              },
                              [
                                _c("span", {
                                  staticClass: "question-text",
                                  domProps: {
                                    innerHTML: _vm._s(item.answer),
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._l(_vm.qa, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `tablet_${index}`,
                    staticClass: "d-none d-md-block d-lg-none w-100 my-2",
                    on: {
                      click: function ($event) {
                        _vm.qa[index].visible = !_vm.qa[index].visible
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "question-content p-4",
                        class: item.visible ? "question-content-show" : "",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("div", [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "question-title d-inline mx-1 text-lioshutan",
                                },
                                [_vm._v(_vm._s(`Q${index + 1}.`))]
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass: "question-title d-inline mx-1",
                                },
                                [_vm._v(_vm._s(item.question))]
                              ),
                            ]),
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: `caret-${
                                  _vm.qa[index].visible ? "up" : "down"
                                }`,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            model: {
                              value: item.visible,
                              callback: function ($$v) {
                                _vm.$set(item, "visible", $$v)
                              },
                              expression: "item.visible",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mt-3",
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item.answer),
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._l(_vm.qa, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `desktop_${index}`,
                    staticClass: "d-none d-md-none d-lg-block my-2",
                    on: {
                      click: function ($event) {
                        _vm.qa[index].visible = !_vm.qa[index].visible
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "question-content p-4",
                        class: item.visible ? "question-content-show" : "",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("div", [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "question-title d-inline mx-1 text-lioshutan",
                                },
                                [_vm._v(_vm._s(`Q${index + 1}.`))]
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass: "question-title d-inline mx-1",
                                },
                                [_vm._v(_vm._s(item.question))]
                              ),
                            ]),
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: `caret-${
                                  _vm.qa[index].visible ? "up" : "down"
                                }`,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            model: {
                              value: item.visible,
                              callback: function ($$v) {
                                _vm.$set(item, "visible", $$v)
                              },
                              expression: "item.visible",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mt-3",
                              },
                              [
                                _c("span", {
                                  staticClass: "question-text",
                                  domProps: {
                                    innerHTML: _vm._s(item.answer),
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }