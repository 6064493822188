<template lang="pug">
.advertise
  .d-block
    .ads
      .ad-container
        //- 無 API 前端寫死版本
        carousel(:perPage="1" :paginationEnabled="true" :autoplay="true" style="z-index:1;" :loop="true" :autoplayTimeout="5000" :speed="1000")
          slide(v-for="data, index in slideContent")
            .ad-slide.d-flex
              .slide-content.jr-light-bg
                .slide-title.jr-main-color
                  p.font-weight-bold {{data.title1}}
                  p.font-weight-bold(v-if="data.title2 !== ''") {{data.title2}}
                .slide-subtitle.jr-main-color
                  p.font-weight-bold(v-if="data.subtitle !== ''") {{data.subtitle}}
                .slide-text
                  p(v-html="data.text")
                .slide-btn.d-flex.justify-content-center.align-items-center
                  b-button.margin-auto(pill variant="primary" v-if="data.btnText" :target="data.btnTarget" :href="data.btnHref") {{data.btnText}}
              .slide-img
                img(:src="data.img")

        //- NOTE: 尚未開通 Banner API，先關閉API 串接版本
        //- carousel(:perPage="1" :paginationEnabled="true" :autoplay="true" style="z-index:1;" :loop="true" :autoplayTimeout="5000" :speed="1000")
        //-   slide(v-for="data, index in slideContent")
        //-     .ad-slide.d-flex
        //-       .slide-content.jr-light-bg
        //-         .slide-title.jr-main-color
        //-           p.font-weight-bold {{data.title}}
        //-           p.font-weight-bold(v-if="data.title2 !== ''") {{data.title2}}
        //-         .slide-subtitle.jr-main-color
        //-           p.font-weight-bold(v-if="data.intro !== ''") {{data.intro}}
        //-         .slide-text
        //-           p(v-html="data.content")
        //-         .slide-btn.d-flex.justify-content-center.align-items-center
        //-           b-button.margin-auto(pill variant="primary" target="_self" :href="data.url") {{data.upload_files[0].alt}}
        //-       .slide-img
        //-         img(:src="data.upload_files[0].file_url")
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import LioshutanApi from '@/api';
import moment from 'moment';

import regular2 from '@/assets/regular_cycle_2.jpg';
import regular3 from '@/assets/regular_cycle_3.jpg';
import regular4 from '@/assets/regular_cycle_4.jpg';
import regular6 from '@/assets/regular_cycle_6.jpg';

import childrensday2024 from '@/assets/childrensday2024/jr_childrensday_banner.jpg';
// Banner First img
// import happyLearning from '@/assets/happy_learning.jpg';
// import anniversaryBanner2023 from '@/assets/anniversary_2023/banner/anniversary_2023_banner.jpg';
import anniversaryBanner2024 from '@/assets/anniversary_2024/jr_10th_bn.png';

import newsBanner from '@/assets/collaboration-with-caves-education/news-banner.png';

import material from '@/assets/material_2023/material_2023_banner.jpg';

import 'bootstrap/dist/css/bootstrap.css';
// import moment from 'moment';

export default {
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    deadlines: '2024-04-15 00:00:00',
    deadlinesAfter: false,
    slideContent: [
      {
        title1: '兒童節 給孩子最好的禮物', // 必填
        title2: '', // 選填
        subtitle: '', // 選填
        text: `
        即日起至 4/14 報名且完成免費英文健檢，即贈「藝術寶盒」，開啟沈浸式英文學習及美學素養，STEAM+ 玩出聰明好腦！<br/>`, // 必填
        btnHref: `${process.env.VUE_APP_BASE_URL}/event/childrensday_2024?m=homepage_bn`, // 必填
        btnText: '立即報名', // 必填
        btnTarget: '_self', // 連結要以當前頁面的方式打開 //必填
        img: childrensday2024, // 必填
      },
      // {
      //   title1: '快樂學習', // 必填
      //   title2: '1 對 1 活潑教學 課程實錄', // 選填
      //   subtitle: '', // 選填
      //   text: `
      //   沉浸式學習環境，不用硬背單字文法。<br/>
      //   從小輕鬆學、說出一口道地好英文！<br/>`, // 必填
      //   btnHref: `#linkToVideoLocation`, // 必填
      //   btnText: '欣賞完整影片', // 必填
      //   btnTarget: '_self', // 連結要以當前頁面的方式打開
      //   img: happyLearning, // 必填
      // },
      {
        title1: 'WUWOW JUNIOR 10週年盛典 學英文10在划算', // 必填
        title2: '', // 選填
        subtitle: '', // 選填
        text: `
        跟大家分享一個重大消息，就是...我們10歲啦！<br/>
        WUWOW JUNIOR 10週年盛典 學英文10在划算<br/>
        眾多好禮送給你👇<br/>
        指定方案 最高可享萬元折抵<br/>
        填寫資料即可領取 #社群精選懶人包#劍橋有聲繪本及單字書 #英文學習單<br/>
        完成免費英文健檢再抽 iPhone 16 <br/>
        <br/>
        12/25 前購買，更能參加台中購物節週週抽好禮，<br/>
        最大獎直接入住豪宅🏛<br/>
        <br/>
        ➡️點選了解更多享好禮`, // 必填
        btnHref: `https://events.wuwowjr.tw/2024/?m=jr_10ann_website_banner_homepage_10ann`, // 必填
        btnText: '了解更多', // 必填
        btnTarget: '_blank', // 連結要以當前頁面的方式打開
        img: anniversaryBanner2024, // 必填
      },
      {
        title1: '連續 3 年 榮獲 Holon IQ', // 必填
        title2: '', // 選填
        subtitle: '教育科技 亞洲150強企業', // 選填
        text: `
        六書堂吳政哲執行曾說過：「我們正在做對的事，有意義的事，成就自己及社會更好的未來。」<br/><br/>
        連續 3 年榮落教育科技企業獎，對六書堂不僅僅是殊榮與肯定， 更時刻提醒著我們肩負推進「科技教育」的責任與使命， 未來六書堂也將持續為學習者創造更高效學習的線上英文學習環境！<br/>`, // 必填
        btnHref: `https://www.facebook.com/WUWOW.Junior/posts/pfbid02Vf7ETtdWvAMyXSpJ6gEQCZhPu2pJ8UtBchgVWpzMx1Fa5Si8mki6cKujC8YYHdZ3l`, // 必填
        btnText: '了解詳情', // 必填
        btnTarget: '_blank', // 連結要以另開分頁的方式打開
        img: 'https://cdn3.wuwow.tw/arts/20231115_HolonIQ_1030x710.jpg', // 必填
      },
      {
        title1: '各大新聞媒體報導！', // 必填
        title2: '', // 選填
        subtitle: '敦煌x六書堂合作授權 WUWOW JUNIOR 兒美教材', // 選填
        text: `
        為提供更優質、豐富的教學資源，<br/>
        WUWOW JUNIOR 宣布取得敦煌集團21st系列教材授權，展開合作！<br/>
        打造台灣學子線上及實體都能夠快樂學習、建立堅實英語基礎的環境。<br/>`, // 必填
        btnHref: `${process.env.VUE_APP_BASE_URL}/news/collaboration-with-caves-education`, // 必填
        btnText: '了解詳細報導', // 必填
        btnTarget: '_blank', // 連結要以另開分頁的方式打開
        img: newsBanner, // 必填
      },
      {
        title1: '敦煌教材授權合作，培養英文、21世紀關鍵能力', // 必填
        title2: '', // 選填
        subtitle: '教材全面換新', // 選填
        text: `
        與敦煌教育集團合作，選用CAVES KIDS教材，<br/>
        教學有效連結生活經驗，學英文同時培養21世紀關鍵能力，<br/>
        讓創新科技邂逅經典教材，成就孩子英語學習新天地。<br/>`, // 必填
        btnHref: `${process.env.VUE_APP_BASE_URL}/event/caves`, // 必填
        btnText: '敦煌書局首家授權兒美教材', // 必填
        btnTarget: '_self', // 連結要以當前頁面的方式打開 //必填
        img: material, // 必填
      },
      {
        title1: '讓孩子愛上學英語！', // 必填
        title2: '', // 選填
        subtitle: '培養孩子未來無限可能', // 選填
        text: `
        孩子從小學英文，好處比你想得更多！<br/>
        「升學競爭力UP」順利通過升學考試<br/>
        「英文語感Get」在兒童大腦發展期<br/>
        「打開孩子視野」從小使用英文接收資訊與知識，<br/>
        擴大寶貝的視野與想像力！`, // 必填
        btnHref: `${process.env.VUE_APP_BASE_URL}/event/trial`, // 必填
        btnText: '立即免費試聽，領好禮！', // 必填
        btnTarget: '_blank', // 連結要以當前頁面的方式打開 //必填
        img: regular6, // 必填
      },
      {
        title1: '國家金舶獎',
        title2: '',
        subtitle: '全台唯一獲獎線上英文',
        text: '感謝來自國家的肯定！WUWOW Jr 將持續堅持做好課程，2030與台灣一同走向雙語國家！',
        btnHref: `${process.env.VUE_APP_BASE_URL}/news/golden-ship-award`,
        btnText: '了解更多',
        btnTarget: '_blank', // 連結要以另開分頁的方式打開
        img: regular2,
      },
      {
        title1: '好評分享',
        title2: '',
        subtitle: '孩子天天期待的英文課',
        text: '「媽媽，今天可以上課嗎？ 」<br>「課程設計貼近需求，隨孩子的程度修正上課方向！」<br><br>量身訂做最適合的教學，讓孩子們主動要求上課，開心開口說英文。',
        btnHref: '#recommend',
        btnText: '詳細上課心得',
        btnTarget: null, // 連結要以另開分頁的方式打開
        img: regular3,
      },
      {
        title1: '學員真心話',
        title2: '',
        subtitle: '從不敢開口到成為日常',
        text: '在無壓氛圍中，維持學習新鮮感，享受學英文的樂趣。<br><br>',
        btnHref: ' https://lihi2.cc/UHIm9',
        btnText: '欣賞完整影片',
        btnTarget: '_blank', // 連結要以另開分頁的方式打開
        img: regular4,
      },
    ],
    timer: null,
    takeDownTime: new Date('2023-06-05 09:30:00').getTime(),
  }),
  created() {
    if (!moment(new Date()).isBetween('2024-04-01 09:00:00', this.deadlines)) {
      this.slideContent.shift();
    }
  },
  mounted() {
    if (!this.takeDownBanner()) {
      this.countTime(this.takeDownBanner);
    }

    // this.getBanners();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async getBanners() {
      const data = await LioshutanApi.notification.getBannerList();
      this.slideContent = data.data;
    },
    countTime(fn) {
      this.timer = setInterval(fn, 1000);
    },
    takeDownBanner() {
      const time = new Date().getTime();
      if (this.takeDownTime <= time) {
        clearInterval(this.timer);
        this.slideContent = this.slideContent.filter((item) => !['2023兒童節活動'].includes(item.title1));
        return this.takeDownTime <= time;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.advertise {
  margin-bottom: 50px;
}

.VueCarousel /deep/ {
  .VueCarousel-wrapper {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    overflow: hidden;
    background-color: var(--light-color);
  }

  .VueCarousel-dot--active {
    background-color: var(--main-color) !important;
  }

  .VueCarousel-dot {
    width: 15px !important;
    height: 15px !important;
    margin-top: 0 !important;
  }
}

.ad-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.ads {
  background: linear-gradient(to right, #bfe1f0, #88c2e8);
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 205px;
    background-color: #FFFFFF;
  }
}

.ad-slide {
  p {
    margin: initial;
  }

  .slide-img {
    width: calc(100% / 3 * 2);

    img {
      width: 100%;
      object-fit: contain;

      /* border-radius: 0 7px 7px 0; */
    }
  }

  .slide-content {
    width: calc(100% / 3);
    padding: 30px;

    /* border-radius: 7px 0 0 7px; */

    display: flex;
    flex-direction: column;
    justify-content: center;

    .slide-title {
      /* font-size: 50px;
      line-height: 75px; */
      font-size: 30px;
    }

    .slide-subtitle {
      /* font-size: 36px;
      line-height: 54px; */
      font-size: 28px;
    }

    .slide-text {
      margin-bottom: 20px;
      margin-top: 20px;
      /* font-size: 18px;
      line-height: 27px; */
      font-size: 14px;
    }

    .slide-btn {
      .btn {
        font-size: 14px;
        box-shadow: 0px 3px 6px #00000029;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .ad-container {
    max-width: 1556px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1600px) {
  .ad-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  /* .advertise {
    .ads {
      .ad-slide {
        .slide-content {
          .slide-title {
            font-size: 40px;
          }

          .slide-subtitle {
            font-size: 28px;
          }
        }
      }

    }
  } */
}

/* 1200 - 1 */
@media screen and (max-width: 1199px) {
  .ad-container {
    max-width: 980px;
    margin: 0 auto;
  }

  /* .advertise {
    .ads {
      .ad-slide {
        .slide-content {
          padding: 15px;

          .slide-title {
            font-size: 38px;
          }
        }
      }
    }
  } */
  .slide-content {

    .slide-title {
      font-size: 26px;
    }

    .slide-subtitle {
      font-size: 24px;
    }

    .slide-text {

      font-size: 14px;
    }
  }
}

@media screen and (max-width: 998px) {
  .ad-container {
    max-width: 760px;
    margin: 0 auto;
  }

  /* .advertise {
    .ads {
      .ad-slide {
        .slide-content {
          .slide-title {
            font-size: 22px;
            margin-bottom: 10px;
          }

          .slide-subtitle {
            font-size: 20px;
          }

          .slide-text {
            font-size: 16px;
            margin-bottom: 15px;
          }

          .slide-btn {
            .btn {
              font-size: 18px;
            }
          }
        }
      }
    }
  } */

  .ad-slide {
    flex-direction: column;

    .slide-img {
      order: 1;
      width: 100%;
    }

    .slide-content {
      order: 2;
      width: 100%;

      .slide-title {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .slide-subtitle {
        font-size: 20px;
      }

      .slide-text {
        font-size: 14px;
        margin-bottom: 15px;
        margin-top: 15px;
      }

      .slide-btn {
        .btn {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ad-container {
    max-width: 575px;
    margin: 0 auto;
  }

  .ad-slide {
    .slide-content {
      .slide-subtitle {
        font-size: 17px;
      }

      .slide-text {
        margin-top: 0px;
        font-size: 14px;
      }
    }
  }
}

/* 576 -1  */
@media screen and (max-width: 575px) {
  .ad-container {
    max-width: 100%;
  }

  .VueCarousel-wrapper {
    box-shadow: none;
    width: 100%;
  }

  .advertise {
    margin-bottom: 0px;
  }

  .ad-slide {
    .slide-content {
      width: 100%;

      .slide-title {
        font-size: 28px;

        p {
          margin-bottom: 0;
        }
      }

      .slide-text {
        font-size: 14px;
      }
    }

    .slide-img {
      width: 100%;
    }
  }
}
</style>
