var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "avatar",
    },
    [
      _vm.avatarUrl === ""
        ? _c("img", {
            staticClass: "w-100",
            attrs: {
              src: _vm.defaultAvaterUrl,
            },
          })
        : _c("img", {
            staticClass: "w-100 h-100",
            attrs: {
              src: _vm.avatarUrl,
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }