// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/login/background4.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/login/background3.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/login/background2.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/login/background1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".login-role-img[data-v-74425164], .login-role-img img[data-v-74425164] {\n  border-radius: 15px;\n}\n.choice-title[data-v-74425164] {\n  position: absolute;\n  width: 100%;\n  top: -36px;\n  font-size: 24px;\n}\n.login-role-img[data-v-74425164] {\n  cursor: pointer;\n  border: 1px solid #fff0;\n  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  position: relative;\n}\n.login-role-img[data-v-74425164]:after {\n    content: '';\n    width: 100%;\n    height: 100%;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    position: absolute;\n    left: 0;\n    top: 0;\n    border-radius: 15px;\n}\n.login-role-img[data-v-74425164]:hover:after {\n    background-image: none;\n}\n.login-role-img[data-v-74425164]:hover {\n    display: inline-block;\n    border: 1px solid var(--light-color);\n    text-shadow: 0px 0px 10px #FFFFFF;\n}\n.login-role-img p[data-v-74425164] {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 36px;\n    color: white;\n    letter-spacing: 0.3em;\n}\n[data-v-74425164] .modal-content {\n  background-color: #fff0 !important;\n  border: none;\n}\n[data-v-74425164] .modal-content .close {\n    color: white;\n    border: 1px solid white;\n    border-radius: 50%;\n    padding: 0;\n    width: 30px;\n    height: 30px;\n    padding-bottom: 4px;\n    opacity: 1;\n    z-index: 1;\n}\n[data-v-74425164] .modal {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-position: center, right bottom, center, center;\n  background-repeat: no-repeat, no-repeat, no-repeat;\n  background-size: cover, auto, cover, cover;\n}\n", ""]);
// Exports
module.exports = exports;
